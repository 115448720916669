// Libs
import { DateTime } from 'luxon';
// Types
import { EventType } from '../../types/types';
// Material UI
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
// MUI Lab
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

interface FilterEventsProps {
  selectedClassName: string;
  setSelectedClassName: (value: string) => void;
  filterDate: DateTime | null;
  setFilterDate: (value: DateTime | null) => void;
  eventTypes: EventType[];
  resetFilters: () => void;
}

export default function FilterEvents({selectedClassName, setSelectedClassName, filterDate, setFilterDate, eventTypes, resetFilters}: FilterEventsProps) {
  return (
    <Box sx={{ padding: '20px' }}>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth>
       <InputLabel id="class-name-select-label">Class Name</InputLabel>
       <Select
         labelId="class-name-select-label"
         id="class-name-select"
         value={selectedClassName}
         onChange={(e) => setSelectedClassName(e.target.value)}
         label="Class Name"
       >
         <MenuItem value="">
           <em>None</em>
         </MenuItem>
         {eventTypes?.map((eventType) => (
           <MenuItem key={eventType.id} value={eventType.name}>
             {eventType.name}
           </MenuItem>
         ))}
       </Select>
        </FormControl>
      </Grid>
   <Grid item xs={12} sm={6}>
     <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-GB">
       <DatePicker
         label="Date"
         value={filterDate}
         onChange={(newValue) => setFilterDate(newValue)}
         sx={{ width: '100%' }}
       />
     </LocalizationProvider>
   </Grid>
   <Grid item xs={12}>
     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
       <Button variant="outlined" onClick={resetFilters}>
         Clear Filters
       </Button>
     </Box>
   </Grid>
    </Grid>
   </Box>
  );
}