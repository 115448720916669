// Libs
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// Hooks
import { useAuth } from "../hooks/useAuth";
// Material-UI
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

export default function ClassPriceList() {
  const navigate = useNavigate();
  const { user, isUserLoading } = useAuth();

  useEffect(() => {
    // Wait until loading is complete before checking the user
    if (!isUserLoading) {
      if (!user) {
        navigate('/'); // Redirects to login if no user
        return;
      }
      if (user.user_type !== 'member') {
        navigate('/searchclasses'); // Redirect to club search classes page if not a member user
        return;
      }
    }
  }, [user, isUserLoading, navigate]);

  const services = [
    { name: 'Little Devils 3-6yrs', price: '£5.50' },
    { name: 'Junior Devils 7-13yrs', price: '£6.50' },
    { name: '5-15yrs Sparring', price: '£6.50' },
    { name: 'Senior Devils 13+', price: '£6.50' },
    { name: 'Senior Sparring', price: '£6.50' },
    { name: 'Train & Spar (All Ages)', price: '£8.00' },
    { name: 'Open Gym', price: '£6.50' },
    { name: 'Morning Kickboxing', price: '£6.50' },
    { name: 'Seniors Boxing', price: '£6.50' },
  ];

  return (
    <>
    <Helmet>
      <title>{`${user?.club_name || ''} | Price List`}</title>
    </Helmet>
    <Container component="main" maxWidth="sm" sx={{ overflow: 'auto', paddingBottom: '100px', pt: '64px' }}>
    <TableContainer component={Paper} sx={{ maxWidth: 650, margin: 'auto', marginTop: 5 }}>
      <Typography variant="h4" sx={{ textAlign: 'center', m: 2 }}>Price List</Typography>
      <Typography sx={{ textAlign: 'center', m: 2 }}>1st April 2024 onwards</Typography>
      <Typography sx={{ fontSize: '0.875rem', textAlign: 'center', m: 2 }}>Payment is required on the day of the class. Alternatively, you may discuss setting up a monthly standing order with Rob to take advantage of potential savings</Typography>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Class Name</TableCell>
            <TableCell align="right">Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service) => (
            <TableRow key={service.name}>
              <TableCell component="th" scope="row">
                {service.name}
              </TableCell>
              <TableCell align="right">{service.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
    </>
  );
}