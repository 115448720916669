import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, CssBaseline, TextField, Checkbox, Container, Typography } from '@mui/material';

import { useAuth } from '../hooks/useAuth';
import Loading from '../components/Alerts/Loading';
import { useAlert, AlertContent } from '../AlertContext';
import AlertMessage from '../components/Alerts/AlertMessage';
import PrivacyPolicyModal from '../components/Modals/PrivacyPolicyModal';

import { FamilyMember } from '../types/types';

const retrieveFamilyMembers = async (userId: string) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/users/api/get_family_members_by_user_id`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    Accept: 'application/json',
  },
    body: JSON.stringify({userId}),
  });
  const json = await response.json();
  const data = json as FamilyMember[];
  return data
};

export default function ManageFamilyMembers() {
  const { alert, showAlert } = useAlert();
  const navigate = useNavigate();
  const { user, isUserLoading, updateUserFamilyMembers } = useAuth();
  
  const [modalOpen, setModalOpen] = useState(false);
  
  const formRef = useRef<HTMLFormElement>(null);
  const generatedContainersRef = useRef<HTMLDivElement[]>([]); // Track dynamically generated family member containers
  
  const queryClient = useQueryClient();
  const { data: familyMembers, error, isLoading } = useQuery({
    queryKey: ['familyMembersData', user?.id],
    queryFn: () => retrieveFamilyMembers(user?.id ?? '')
  });

  // Restrict access to page if user is not of type 'member'
  useEffect(() => {
    // Wait until loading is complete before checking the user
    if (!isUserLoading) {
      if (!user) {
        navigate('/'); // Redirects to login if no user
        return;
      }
      if (user.user_type !== 'member') {
        navigate('/clublanding'); // Redirect to user landing page if not a member user
        return;
      }
    }
  }, [user, isUserLoading, navigate]);

  // Formik/Yup Validations
  const formValidationSchema = Yup.object().shape({
    newFamilyMembers: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string().required().min(2).label('First Name'),
        last_name: Yup.string().required().min(2).label('Last Name'),
        consent: Yup.boolean().required().oneOf([true], 'You must agree to the privacy policy'),
        id: Yup.number(),
        user_id: Yup.number(),
      })
    ),
  });

  const formik = useFormik({
    initialValues: { first_name: '', last_name: '', user_id: user?.id, consent: false },
    validationSchema: formValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      // If the user has not consented to the privacy policy, do not submit the form
      if (!values.consent) {
        return;
      }
      await addFamilyMember(values, resetForm);
      resetForm({
        values: {
          first_name: '',
          last_name: '',
          user_id: user?.id,
          consent: false,
        },
      });
    },
  });

  const deleteFamilyMember = async (familyMemberId: string, userId: string) => {
    try {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/users/api/remove_family_member`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({familyMemberId: familyMemberId, userId: userId}),
      });
      const json = await response.json();
      if (response.ok) {
        const alertContent: AlertContent = {
          message: json.success.message,
          subMessage: json.success.subMessage,
          status: 'success',
        };
        showAlert(alertContent);
        // Update user data with familyMember data in React Query cache and localStorage
        updateUserFamilyMembers(json.familyMembers);
        // Update react-query cache
        queryClient.invalidateQueries({ queryKey: ['familyMembersData', user?.id] });
      } else if (response.status === 404) {
        console.error('Error when removing family member, please try again.');
        const alertContent: AlertContent = {
          message: json.error.message,
          subMessage: json.error.subMessage,
          status: 'error',
        };
        showAlert(alertContent);
      } else {
        throw `Error with fetch request: status ${response.status} ${response.statusText}`;
      }
    } catch (error) {
      console.log(`Error when removing family member: ${error}`);
    }
  };
  
  const handleRemoveFamilyMemberUI = (containerToRemove: HTMLDivElement) => {
    // Remove the container from the DOM
    containerToRemove.remove();

    // Remove the container from the generatedContainersRef
    const updatedContainers = generatedContainersRef.current.filter((container) => container !== containerToRemove);
    generatedContainersRef.current = updatedContainers;
    // Update formik.values.consent
    formik.setFieldValue('consent', false);
  };

  const addFamilyMember = async (data: any, resetForm: () => void) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/users/api/add_family_member`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const json = await response.json();
  
      if (json.error) {
        // Handle error uniformly
        const alertContent: AlertContent = {
          message: json.error.message,
          subMessage: json.error.subMessage,
          status: 'error',
        };
        showAlert(alertContent);
         // Clear the generatedContainersRef
         handleRemoveFamilyMemberUI(generatedContainersRef.current[0]);
         // Reset form inputs
         resetForm();
      } else {
        // Handle success
        const alertContent: AlertContent = {
          message: json.success.message,
          subMessage: json.success.subMessage,
          status: 'success',
        };
        showAlert(alertContent);
        // Update user data with familyMember data in React Query cache and localStorage
        updateUserFamilyMembers(json.familyMembers);
        // Update react-query cache
        queryClient.invalidateQueries({ queryKey: ['familyMembersData', user?.id] });
        // Clear the generatedContainersRef
        handleRemoveFamilyMemberUI(generatedContainersRef.current[0]);
        // Reset form inputs
        resetForm();
      }
    } catch (error) {
      const alertContent: AlertContent = {
        message: 'Error',
        subMessage: 'Please Contact Support',
        status: 'error',
      };
      showAlert(alertContent);
      // Clear the generatedContainersRef
      handleRemoveFamilyMemberUI(generatedContainersRef.current[0]);
      // Reset form inputs
      resetForm();
    }
  };
  
  const handleAddFamilyMemberUI = () => {
    if (generatedContainersRef.current.length > 0) {
      return;
    }

    const form = formRef.current; // Get the form element from the ref
    if (form) {
      const container = document.createElement('div'); // Create a container for the new family member inputs
      container.classList.add('family-member-container');
      
      // Create the text fields for first name and last name
      const firstNameInput = (
        <TextField
          label="First Name"
          placeholder="First Name"
          name="first_name"
          onChange={(e) => formik.setFieldValue("first_name", e.target.value)}
          defaultValue={formik.values.first_name}
          error={formik.touched.first_name && Boolean(formik.errors.first_name)}
          fullWidth
          margin="normal"
          variant="outlined"
        />
      );

      const lastNameInput = (
        <TextField
          label="Last Name"
          placeholder="Last Name"
          name="last_name"
          onChange={(e) => formik.setFieldValue("last_name", e.target.value)}
          defaultValue={formik.values.last_name}
          error={formik.touched.last_name && Boolean(formik.errors.last_name)}
          fullWidth
          margin="normal"
          variant="outlined"
        />
      );

      // Create checkbox for consent
      const consentCheckbox = (
        <>
          <Checkbox
            id="consent"
            name="consent"
            onChange={e => {
              formik.setFieldValue('consent', e.target.checked);
            }}
          />
          <Typography fontSize={'0.7rem'} component="span" style={{ cursor: 'auto' }}>
            By clicking this checkbox, you agree to the{' '}
          </Typography>
          <span onClick={handlePrivacyPolicyClick}>
            <Typography fontSize={'0.7rem'} component="span" color="primary" style={{ cursor: 'pointer' }}>
              privacy policy
            </Typography>
          </span>
        </>
      );

      // Create remove button
      const removeButton = (
        <Button
          sx={{marginRight: '5px'}}
          variant="contained"
          color="error"
          onClick={() => handleRemoveFamilyMemberUI(container)}
        >
          Remove
        </Button>
      );

      // Save button
      const saveButton = (
        <Button
          variant="contained"
          color="primary"
          type='submit'
          disabled={formik.isSubmitting}
        >
          Save
        </Button>
      );
      
      ReactDOM.render(
        <Box display="flex" flexDirection="column">
          {firstNameInput}
          {lastNameInput}
          <Box>
            {consentCheckbox}
            {removeButton}
            {saveButton}
          </Box>
        </Box>,
        container
      );
  
      form.appendChild(container);
      generatedContainersRef.current.push(container);
    }
  };

  const handlePrivacyPolicyClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // If still loading, show loading state
  if (isUserLoading || isLoading) {
    return <Loading text="" />
  }

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <Container component="main" maxWidth="xs" sx={{ overflow: 'auto', paddingBottom: '100px', pt: '64px' }}>
      <PrivacyPolicyModal modalOpen={modalOpen} handleCloseModal={handleCloseModal}/>
      {alert && (
        <AlertMessage />
      )}
      <CssBaseline />
      <Box textAlign={'center'} sx={{marginTop: '5px'}}>
        <Typography sx={{fontWeight: 'bold', padding: '10px'}}>Manage Family Members</Typography>
      </Box>
      <Box>
      {familyMembers?.map((familyMember, index) => (
        <Box key={index}>
          <TextField
            label="First Name"
            name={`familyMembers[${index}].first_name`}
            value={familyMember.first_name}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            name={`familyMembers[${index}].last_name`}
            value={familyMember.last_name}
            fullWidth
            margin="normal"
          />
          <Box>
            <Button
              onClick={() => { deleteFamilyMember(`${familyMembers[index].id}`, user?.id) }}
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          </Box>
        </Box>
      ))}
      <form id="add-family-member" ref={formRef} onSubmit={formik.handleSubmit}>


      </form>
      <>
      <Box textAlign={'center'} sx={{marginTop: '15px'}}>
      <Button
        onClick={handleAddFamilyMemberUI}
        variant="contained"
        color="primary"
      >
      Add Family Member
      </Button>
    </Box>
    </>
    </Box>
  </Container>
  );
};

