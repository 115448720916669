import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import { Box, Container, CssBaseline, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import Loading from "../components/Alerts/Loading";

export default function Home() {
  const { user, isUserLoading } = useAuth();
  const navigate = useNavigate();
  const [club, setClub] = useState('');
  
  const handleChange = (event: any) => {
    const selectedClub = event.target.value;
    setClub(selectedClub);
    navigate(`/signin/${selectedClub}`);
  };

  useEffect(() => {
    // If user data is loaded and user is logged in, navigate to the landing page
    if (!isUserLoading && user) {
      if (user.user_type === 'club') {
        navigate('/clublanding');
      } else {
        navigate('/userlanding');
      }
    }
  }, [user, isUserLoading, navigate]);

  if (isUserLoading) {
    return <Loading text="" />
  }
  

  // Render the sign in form if there's no user logged in
  return (
    <>
      <Helmet>
        <title>Home | Book Me In</title>
      </Helmet>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ marginTop: 4, marginBottom: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img
            style={{ width: '100%', height: 165 }}
            src="https://bookmeinapp.s3.eu-west-1.amazonaws.com/shared/bookmeinlogo.png"
            alt="Book Me In"
          />
          <FormControl fullWidth>
            <InputLabel id="club-select-label">Choose Your Club</InputLabel>
            <Select
              label="Choose Your Club"
              value={club}
              onChange={handleChange}
              displayEmpty
            >
              <MenuItem disabled>Wales</MenuItem>
              <MenuItem value="devilsmartialarts">Devils Martial Arts</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Divider sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: 'bold'}} align="center">
              Want to Add Your Club?
            </Typography>
        </Divider>

        <Typography align="center">
          Email: bookmeinuk@gmail.com
        </Typography>
      </Container>
    </>
  );
}