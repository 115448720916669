
import { showFriendlyDate, showFriendlyClassStartEnd } from '../../helpers/Utilities';
import { Card, CardContent, Typography, Box } from '@mui/material';

import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

export default function ClubLandingList({ todaysClass, user, navigate }: { todaysClass: any, user: any, navigate: any }) {
  return (
    <Card key={todaysClass.id} sx={{mb: 2, '&:hover': { cursor: 'pointer' }}} onClick={() => navigate(`/attendees/${todaysClass.id}`)}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" sx={{marginBottom: '5px'}}>
          {/* Class Name & Class Numbers */}
          <Typography sx={{fontWeight: '500', paddingLeft: '4px'}} key={todaysClass.id}>{todaysClass.name}</Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              <PermIdentityOutlinedIcon sx={{ fontSize: 'med' }}/>
              <Typography>{todaysClass.spaces_booked} / {todaysClass.event_limit}</Typography>
            </Box>
        </Box>
        {/* Class Date */}
        <Box display="flex" sx={{marginBottom: '5px'}}>
          <CalendarMonthOutlinedIcon sx={{ fontSize: 'med', marginRight: '5px' }} />
          <Typography>{showFriendlyDate(todaysClass.date, user?.timezone)}</Typography>
        </Box>
        {/* Class Start/End Time */}
        <Box display="flex" sx={{marginBottom: '5px'}}>
          <AccessTimeOutlinedIcon sx={{ fontSize: 'med', marginRight: '5px' }} />
          <Typography>{showFriendlyClassStartEnd(todaysClass.date, todaysClass.duration, user?.timezone)}</Typography>
        </Box>
      </CardContent>
    </Card>
    )
}