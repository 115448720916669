// Libs
import { Fragment } from "react"
// Helpers
import { showFriendlyDate, showFriendlyClassStartEnd } from '../../helpers/Utilities';
// Material-UI
import { Box, Card, CardContent, Typography  } from "@mui/material"
// Material-UI Icons
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

export default function AllClubClassesList({ date, classItems, user, navigate }: { date: string, classItems: any[], user: any, navigate: any}) {
  return (
    <Fragment key={date}>
      <Typography sx={{fontWeight: '500', marginBottom: '3px'}}>{date}</Typography> {/* This is the subheader */}
      {classItems.map((classItem: any) => (
        <Card key={classItem.id} sx={{ mb: 2, '&:hover': { cursor: 'pointer'} }} onClick={() => navigate(`/attendees/${classItem.id}`)}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" sx={{ marginBottom: '5px' }}>
              {/* Class Name & Class Numbers */}
              <Typography sx={{ fontWeight: '500', paddingLeft: '4px' }}>{classItem.name}</Typography>
              <Box display="flex" flexDirection="row" alignItems="center">
                <PermIdentityOutlinedIcon sx={{ fontSize: 'med' }} />
                <Typography>{classItem.spaces_booked} / {classItem.event_limit}</Typography>
              </Box>
            </Box>
            {/* Class Date */}
            <Box display="flex" justifyContent="space-between" sx={{ marginBottom: '5px' }}>
              <Box display="flex" alignItems="center">
                <CalendarMonthOutlinedIcon sx={{ fontSize: 'med', marginRight: '5px' }} />
                <Typography>{showFriendlyDate(classItem.date, user?.timezone)}</Typography>
              </Box>
            </Box>
            {/* Class Start/End Time */}
            <Box display="flex" sx={{ marginBottom: '5px' }}>
              <AccessTimeOutlinedIcon sx={{ fontSize: 'med', marginRight: '5px' }} />
              <Typography>{showFriendlyClassStartEnd(classItem.date, classItem.duration, user?.timezone)}</Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Fragment>
  )
}