import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/useAuth';
import { useAlert, AlertContent } from '../../AlertContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';

import AlertMessage from '../Alerts/AlertMessage';

import { Avatar, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, IconButton, InputAdornment, Link, TextField, Typography  } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Yup validation schema
const SignInSchema = Yup.object().shape({
    signInEmail: Yup.string()
      .required('Email Is Required'),
    signInPassword: Yup.string()
      .required('Password Is Required'),
});

const handleClickShowPassword = (show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>) => () => {
  setShow((prevShow) => !prevShow);
};

const handleMouseDown = (event: React.MouseEvent) => {
  event.preventDefault();
};

export default function SignInForm() {
  const { clubName } = useParams();
  const { signIn } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { alert, showAlert } = useAlert();
  
  const queryParams = queryString.parse(location.search);
  const isEmailVerified = queryParams.emailVerified === 'true';
  const isLoggedOut = queryParams.loggedOut === 'true';
  
  // Set Messages Based on Query Params
  useEffect(() => {
    if (isLoggedOut) {
      const alertContent: AlertContent = {
        message: "Logged out successfully!",
        subMessage: "You can now sign in again.",
        status: 'success',
      };
      showAlert(alertContent);
    }

    if (isEmailVerified) {
      const alertContent: AlertContent = {
        message: "Email Verified Successfully!",
        subMessage: "You can now sign in.",
        status: 'success',
      };
      showAlert(alertContent);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const formik = useFormik({
      initialValues: {
      signInEmail: '',
      signInPassword: '',
    },
    validationSchema: SignInSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      sendRequest(values);
    },
  });

  const sendRequest = async (values: { signInEmail: string; signInPassword: string; }) => {
    // Destructure formik values to match expected signIn parameters
    const { signInEmail, signInPassword } = values;
  
    signIn({ signInEmail, signInPassword }, {
      onSuccess: (user) => {
        // Navigate based on user type
        if (user?.user_type === 'club') {
          navigate("/clublanding");
        } else {
          navigate("/userlanding");
        }
      },
      onError: (error: any) => {
        // Need to parse error message as its a standard Error object
        // in useAuth hook we throw new Error(JSON.stringify({message: jsonData.error.message, subMessage: jsonData.error.subMessage}));
        // Standard Error object doesn't have a subMessage property by default, only message
        const parsedError = JSON.parse(error.message);
        const errorMessage = parsedError.message;
        const errorSubMessage = parsedError.subMessage;
        const alertContent: AlertContent = {
          message: errorMessage,
          subMessage: errorSubMessage,
          status: 'error',
        };
        showAlert(alertContent);
      },
    });
  };

  // Show/Hide Password & Password Confirmation
  const [showPassword, setShowPassword] = React.useState(false);
  
  return (
      <Container component="main" maxWidth="xs" sx={{ pt: '64px' }}>
        {alert && (
          <AlertMessage />
        )}
        <CssBaseline />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar
            sx={{ m: 1, width: 66, height: 66 }}
            src={`https://bookmeinapp.s3.eu-west-1.amazonaws.com/${clubName}/logo.png`}
            alt="Club Logo"
          />
          <Typography component="h2" variant="h6">
            Sign In
          </Typography>
          <form onSubmit={formik.handleSubmit}>
          <Box sx={{ mt: 1 }}>
            <TextField
              onChange={formik.handleChange}
              error={formik.touched.signInEmail && Boolean(formik.errors.signInEmail)}
              helperText={formik.touched.signInEmail && formik.errors.signInEmail}
              value={formik.values.signInEmail}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="signInEmail"
              autoFocus
              autoComplete="username"
            />
            <TextField
              onChange={formik.handleChange}
              error={formik.touched.signInPassword && Boolean(formik.errors.signInPassword)}
              helperText={formik.touched.signInPassword && formik.errors.signInPassword}
              value={formik.values.signInPassword}
              margin="normal"
              required
              fullWidth
              name="signInPassword"
              label="Password"
              id="password"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword(showPassword, setShowPassword)}
                      onMouseDown={handleMouseDown}
                      edge="end">
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
          </form>
          <Grid container>
              <Grid item xs>
                <Link style={{ textDecoration: 'none' }} href="/forgotpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link 
                   style={{ textDecoration: 'none' }}
                   href={`/signup/${clubName}`}
                   variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
        </Box>
      </Container>
  );
}