// Libs
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query'
// Hooks
import { useAuth } from '../hooks/useAuth';
// Api Requests
import { getEventTypeByUserId } from '../api/EventTypes';
// Types
import { EventType } from '../types/types';
// Components
import Loading from "../components/Alerts/Loading";
import EventTypeCard from '../components/Events/EventTypeCard';
// Material-UI
import { Box, Button, Card, CardContent, Container, CssBaseline, Typography } from '@mui/material';
// Material-UI Icons
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

export default function EditClassType() {
  const { user, isUserLoading } = useAuth();
  const navigate = useNavigate();
  // useQuery hook from react-query
  const { data: eventTypes, error, isLoading } = useQuery({
    queryKey: ['clubEventTypes', user?.id],
    queryFn: () => getEventTypeByUserId(user?.id ?? '')
  });

  // Restrict access to page if user is not of type 'club'
  useEffect(() => {
    // Wait until loading is complete before checking the user
    if (!isUserLoading) {
      if (!user) {
        navigate('/'); // Redirects to login if no user
        return;
      }
      if (user.user_type !== 'club') {
        navigate('/userlanding'); // Redirect to user landing page if not a club user
        return;
      }
    }
  }, [user, isUserLoading, navigate]);

  if (isUserLoading || isLoading) {
    return <Loading text="" />
  }

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <Container component="main" maxWidth="sm" sx={{ overflow: 'auto', paddingBottom: '100px', pt: '64px' }}>
      <CssBaseline />
      <Box sx={{ marginTop: '15px', marginBottom: '15px', textAlign: 'center'}}>
        <Typography sx={{fontWeight: '600'}}>Current Class Types</Typography>
      </Box>

      {/* Create Class Type Button */}
      {eventTypes?.length === 0 && (
      <Box textAlign={'center'} sx={{marginTop: '15px'}}>
        <Card >
          <CardContent>
            <Box>
              <Typography sx={{fontWeight: '600'}}>No Class Types Found</Typography>
              <LocalActivityIcon fontSize="large"/>
            </Box>
            <Button variant="contained" onClick={() => navigate('/createclasstype')}>Create Class Type</Button>
          </CardContent>
        </Card>
      </Box>
      )}

      {eventTypes?.map((eventType: EventType) => (
        <EventTypeCard key={eventType.id} eventType={eventType} navigate={navigate} />
      ))}
    </Container>
  );
}