import * as Yup from 'yup';
import { ukMobileRegex, strongPasswordRegex } from '../helpers/RegExPatterns';

export const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'First Name Is Required')
      .required('First Name Is Required'),
    lastName: Yup.string()
      .min(2, 'Last Name Is Required')
      .required('Last Name Is Required'),
    clubName: Yup.string()
      .oneOf(['Devils Martial Arts'], 'Club Name Is Invalid')
      .required('Club Name Is Required'),
    userType: Yup.string()
      .oneOf(['member'], 'User Type Is Invalid')
      .required('User Type Is Required'),
    email: Yup.string()
      .email('Please enter a valid email address.')
      .required('Email Is Required'),
    telephone: Yup.string()
      .matches(ukMobileRegex, 'Must be a valid UK mobile number.')
      .required('Telephone Is Required'),
    password: Yup.string()
      .matches(strongPasswordRegex, 'Must be at least 8 characters long and contain at least 1 uppercase, 1 number, 1 special character.')
      .required('Password Is Required'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords Do Not Match')
      .required('Password Confirmation Is Required'),
  });