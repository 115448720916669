// Libs
import * as React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// Global Context

// Hooks
import { useAuth } from '../../hooks/useAuth';
// Helpers
import { getClubNameToLowerCase } from '../../helpers/ClubNames';
// Material-UI
import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
// Material-UI Icons
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PeopleIcon from '@mui/icons-material/People';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';

export default function NavigationBar() {
  const { user, signOut } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  
  if (!user) return null;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
      (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsOpen(open);
  };

  const memberList = () => (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        {[
          {text: 'Home', path: '/userlanding'},
          {text: 'Search Classes', path: '/bookclasses'},
          { text: 'Profile', path: '/profile' },
          { text: 'Manage Family', path: '/managefamily' },
          { text: 'Prices', path: '/pricelist'},
          { text: 'Contact', path: '/contact'}].map(item => (
    <ListItem key={item.text} component={RouterLink} to={item.path} button disablePadding>
      <ListItemButton>
        <ListItemIcon>
          {{
            'Home': <HomeIcon />,
            'Search Classes': <SearchIcon />,
            'Profile': <AccountBoxIcon />,
            'Manage Family': <PeopleIcon />,
            'Prices': <CurrencyPoundIcon />,
            'Contact': <InfoIcon />
          }[item.text]}
        </ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    </ListItem>
    ))}
  </List>
  <Divider />
  {signOutLink()}
</Box>
  );

  const clubList = () => (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
    <List>
    {[
     {text: 'Home', path: '/clublanding'},
     {text: 'Search Classes', path: '/searchclasses'},
     {text: 'Create Class Type', path: '/createclasstype'},
     { text: 'Edit Class Type', path: '/editclasstype' },
     { text: 'Create Class', path: '/createclass' },
     { text: 'Profile', path: '/profile' },
     { text: 'Members', path: '/members'}
    ].map(item => (
    <ListItem key={item.text} component={RouterLink} to={item.path} button disableRipple disablePadding>
      <ListItemButton>
        <ListItemIcon>
          {{
            'Home': <HomeIcon />,
            'Search Classes': <SearchIcon />,
            'Create Class Type': <AddIcon />,
            'Edit Class Type': <ModeEditIcon />,
            'Create Class': <AddIcon />,
            'Profile': <AccountBoxIcon />,
            'Members': <PeopleIcon />
          }[item.text]}
        </ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    </ListItem>
    ))}
  </List>
  <Divider />
  {signOutLink()}
  </Box>
  );

  const signOutLink = () => (
    <ListItem disablePadding>
    <ListItemButton>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      { user
        ? <ListItemText primary="Sign Out" onClick={handleSignOut} />
        : (
            <RouterLink to='/signin' style={{ textDecoration: 'none' }}>
              <ListItemText primary="Sign In" />
            </RouterLink>
          )
      }
    </ListItemButton>
  </ListItem>

  );

  const handleSignOut = () => {
    const clubName = getClubNameToLowerCase(user.club_name);
    const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/api/logout`;
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => res.json())
    .then(data => {
      if (data.success) {
        navigate(`/signin/${clubName}?loggedOut=true`); // Redirect the user to the sign-in page or wherever you want
        signOut(); // Clear user data in cache
      } else {
        console.error(data.message);
      }
    })
    .catch(error => {
      console.error("Failed to sign out:", error);
    });
  };

return (
<div>
  <AppBar position="fixed">
    <Toolbar>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      {/* Add any other elements or text for your navbar here. */}
    </Toolbar>
  </AppBar>

  <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
    {user.user_type === 'club' ? clubList() : memberList()}
  </Drawer>
</div>
);
}