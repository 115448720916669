import { Box, Button, Card, CardContent  } from '@mui/material';

export default function EventTypeCard({eventType, navigate}: {eventType: any, navigate: any}) {    
  return (
    <Card key={eventType.id} sx={{mb: 2}}>
      <Box display="flex" justifyContent="space-between">
        <CardContent>
          <div key={eventType.id}>{eventType.name}</div>
        </CardContent>
        <Button 
            key={eventType.id}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              localStorage.setItem('existingEvent', JSON.stringify(eventType));
              navigate('/createclasstype');
            }}>
            Edit
        </Button>
      </Box>
    </Card>
  )
}